import React from 'react'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import AboutPartnerSection from '../components/AboutComponents/AboutPartnerSection'
import AboutHeroSection from '../components/AboutComponents/AboutHeroSection'
import AboutCtaSection from '../components/AboutComponents/AboutCtaSection'
import AboutMissionSection from '../components/AboutComponents/AboutMissionSection'
import AboutTeamSection from '../components/AboutComponents/AboutTeamSection'
import { useRecordPageDuration } from '../components/utils'
import AboutCarouselSection from '../components/AboutComponents/AboutCarouselSection'

const pageTitle = 'Flock | About'
const pageDescription = `We’re on a mission to deliver financial freedom to owners, better living experiences for residents, and durable value to the community.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const About = () => {
  useRecordPageDuration()

  return (
    <PageWrapper
      title={pageTitle}
      trackingName="about"
      headerBackground="trustBlue.main"
    >
      <AboutHeroSection />
      <AboutMissionSection />
      <AboutTeamSection />
      <AboutCarouselSection />
      <AboutPartnerSection />
      <AboutCtaSection />
    </PageWrapper>
  )
}

export default About
