import React from 'react'

import { Grid, Typography } from '@mui/material'
import SectionLayout from '../SharedComponents/SectionLayout'

const AboutHeroSection = () => (
  <SectionLayout name="about-hero-section" backgroundColor="trustBlue.main">
    <Grid container item sm={12}>
      <Typography variant="h1" color="moneyGreen.main">
        Empowering landlords with a better way to retire.
      </Typography>
    </Grid>
  </SectionLayout>
)

export default AboutHeroSection
