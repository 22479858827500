import React from 'react'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import SectionLayout from '../SharedComponents/SectionLayout'
import InlineMessageAddress from '../SharedComponents/InlineMessageAddress'
import { WebinarModal } from '../SharedComponents/WebinarModal'

const AboutCtaSection = () => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <SectionLayout
      name="hero-section"
      backgroundColor="trustBlue.main"
      verticalPadding="0px"
    >
      <Grid item xs={12}>
        <InlineMessageAddress
          text="Landlords, retire and minimize taxes."
          variant={isTablet ? 'button' : 'address'}
          label="Get Started"
        />
      </Grid>
      <Grid item xs={12} textAlign="end" pb="64px">
        <WebinarModal />
      </Grid>
    </SectionLayout>
  )
}

export default AboutCtaSection
