import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import gsap from 'gsap'
import Draggable from 'gsap/Draggable'
import { horizontalAnimationLoop } from '../animationUtils'

type InfiniteCarouselProps = {
  children: React.ReactNode
  name: string
  margin?: string
  reverse?: boolean
}

const InfiniteCarousel = (props: InfiniteCarouselProps) => {
  const { children, reverse, name, margin } = props

  const singleClassName = `marquee-single-${name}`
  const innerClassName = `${name}-item`

  useEffect(() => {
    // Handles all of the animation + dragging functionality
    // Adapted from https://codepen.io/Daniyar01/pen/oNGxgBL
    gsap.registerPlugin(Draggable)
    const loops = gsap.utils
      .toArray(`.${singleClassName}`)
      .map((line: any, i) => {
        const links = line.querySelectorAll(`.${innerClassName}`)
        const tl: any = horizontalAnimationLoop(links, {
          repeat: -1,
          speed: 0.25 + i * 0.5,
          draggable: true,
          snap: false,
          reversed: reverse,
          paddingRight: parseFloat(
            gsap.getProperty(links[0], 'marginRight', 'px') as string
          ),
        }) as any
        const mouseEnter = () => gsap.to(tl, { timeScale: 0, overwrite: true })
        const mouseLeave = () => gsap.to(tl, { timeScale: 1, overwrite: true })
        links.forEach((link: any) => {
          link.addEventListener('mouseenter', mouseEnter)
          link.addEventListener('mouseleave', mouseLeave)
        })

        return () => {
          links.forEach((link: any) => {
            link.removeEventListener('mouseenter', mouseEnter)
            link.removeEventListener('mouseleave', mouseLeave)
          })
        }
      }, [])

    let currentScroll = 0
    let scrollDirection = 1

    window.addEventListener('scroll', () => {
      const direction = window.pageYOffset > currentScroll ? 1 : -1
      if (direction !== scrollDirection) {
        loops.forEach((tl) =>
          gsap.to(tl, { timeScale: direction, overwrite: true })
        )
        scrollDirection = direction
      }
      currentScroll = window.pageYOffset
    })
  }, [innerClassName, reverse, singleClassName])

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      sx={{
        zIndex: 999,
        overflow: 'hidden',
        '&:before': {
          background: `linear-gradient(to right,  rgba(235,246,255,1) 0%,rgba(235,246,255,0) 100%)`,
          content: '""',
          height: '500px',
          position: 'absolute',
          width: '25px',
          zIndex: 2,
          left: 0,
          top: 0,
        },

        '&:after': {
          background:
            'linear-gradient(to right,  rgba(235,246,255,1) 0%,rgba(235,246,255,0) 100%)',
          content: '""',
          height: '500px',
          position: 'absolute',
          width: '25px',
          zIndex: 2,
          right: 0,
          top: 0,
          transform: 'rotateZ(180deg)',
        },
      }}
    >
      <Box
        className={singleClassName}
        position="relative"
        display="flex"
        margin="0px"
        sx={{
          p: '0px',
          willChange: 'transform',
        }}
      >
        {[1, 2, 3].map((num: number) => (
          <Box
            component="span"
            key={num}
            className={innerClassName}
            display="flex"
            gap={margin}
            marginRight={margin}
          >
            {children}
          </Box>
        ))}
        {children}
      </Box>
    </Box>
  )
}

export default InfiniteCarousel

InfiniteCarousel.defaultProps = {
  margin: '24px',
  reverse: false,
}
