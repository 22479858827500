import React from 'react'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import SectionLayout from '../SharedComponents/SectionLayout'
import AboutStoryImage from '../../images/about-story-image.webp'
import AboutStoryImageMobile from '../../images/about-story-image-mobile.webp'

const AboutMissionSection = () => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <SectionLayout
      name="about-story"
      backgroundColor="trustBlue.main"
      verticalPadding="0px"
    >
      <Grid item xs={12}>
        <Typography variant="h2" color="gray8.main">
          Our story.
        </Typography>
      </Grid>
      {isTablet && (
        <Grid item xs={12}>
          <Box
            component="img"
            src={isMobile ? AboutStoryImageMobile : AboutStoryImage}
            width="100%"
          />
        </Grid>
      )}
      <Grid item xs={isTablet ? 12 : 6}>
        <Box
          display="flex"
          flexDirection="column"
          height={!isTablet ? '296px' : undefined}
          justifyContent="space-between"
          gap="8px"
        >
          <Typography variant="p1" color="gray8.main">
            For years, Ari balanced his jobs as a landlord and hedge fund
            manager. As life priorities shifted, he confronted a problem all
            landlords eventually face: how do you retire from managing rentals,
            without sacrificing hard-earned gains?
          </Typography>
          <Typography variant="p1" color="gray8.main">
            As an asset manager, he saw what the ultra-wealthy used: the 721
            Exchange. But, it was highly technical, requiring a team of
            specialists, and inaccessible to everyday investors.
          </Typography>
          <Typography variant="p1" color="gray8.main">
            From this, Flock Homes was born. In 2020, Ari founded the first
            seamless, tech-enabled 721 Exchange platform and real estate fund
            accessible to individual real estate investors.
          </Typography>
        </Box>
      </Grid>
      {!isTablet && (
        <Grid item xs={6}>
          <Box
            component="img"
            src={AboutStoryImage}
            width="544px"
            height="296px"
          />
        </Grid>
      )}
    </SectionLayout>
  )
}

export default AboutMissionSection
