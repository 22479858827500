import { QuotationLargeIcon } from '@flock/shared-ui'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import InfiniteCarousel from '../PortfolioComponents/InfiniteCarousel'
import WayneAndVickieImage from '../../images/wayne and vickie lackman.webp'
import AndyBedardImage from '../../images/andy bedard.webp'
import JoshBrodbeckImage from '../../images/josh brodbeck.webp'
import ShirleeTurnerImage from '../../images/shirlee turner.webp'
import BobBakerImage from '../../images/bob baker.webp'
import MarkSikorskiImage from '../../images/mark sikorski.webp'
import JeremyHakesImage from '../../images/jeremy hakes.webp'
import JackAndVickiImage from '../../images/jack and vicki somppi.webp'
import RobertWeissfeldImage from '../../images/robert weissfeld.webp'
import MichaelClawsonImage from '../../images/michael clawson.webp'
import RandyMoserImage from '../../images/randy-moser.webp'
import JohnAndReginaImage from '../../images/john and regina wallace.webp'
import KatieAndMarkImage from '../../images/katie and mark jetten.webp'
import SectionLayout from '../SharedComponents/SectionLayout'

const CarouselContainer = (props: any) => {
  const { children } = props
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  if (isTablet) {
    return (
      <>
        <SectionLayout
          name="about-carousel"
          backgroundColor="trustBlue.main"
          verticalPadding="0px"
        >
          <Grid item xs={12}>
            <Typography variant="h2" color="gray8.main">
              We’ve helped more than 100+ clients.
            </Typography>
          </Grid>
        </SectionLayout>
        <Box
          sx={{ backgroundColor: 'trustBlue.main' }}
          pt={isTablet ? '32px' : '64px'}
        />
        <Box sx={{ backgroundColor: 'trustBlue.main' }} gap="32px">
          {children}
        </Box>
      </>
    )
  }
  return (
    <SectionLayout
      name="about-carousel"
      backgroundColor="trustBlue.main"
      verticalPadding="0px"
    >
      <Grid item xs={12}>
        <Typography variant="h2" color="gray8.main">
          We’ve helped more than 100+ clients.
        </Typography>
      </Grid>
      <Box pt={isTablet ? '32px' : '64px'} />
      <Grid item xs={12}>
        {children}
      </Grid>
    </SectionLayout>
  )
}

const AboutCarouselSection = () => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const items = [
    {
      icon: AndyBedardImage,
      name: 'Andy',
      quote:
        'Flock offered me a way to own a little piece of all their properties, be more diversified, and not have all eggs in one basket. That was attractive to me, and I still get quarterly cash flow.',
    },
    {
      icon: JoshBrodbeckImage,
      name: 'Josh',
      quote:
        'Ever since turning the property over to Flock, everything has gone the exact way it was suggested to go. I wish I found out about Flock 45 days earlier, so I could have contributed more properties.',
    },
    {
      icon: ShirleeTurnerImage,
      name: 'Shirlee',
      quote:
        'You aren’t worried about that 12 a.m. call that the pipe froze or the water heater stopped working. You sleep better.',
    },
    {
      icon: BobBakerImage,
      name: 'Bob',
      quote:
        'Flock Homes has been an incredible gift to me and to two of my family members, who had been helping me manage two properties.',
    },
    {
      icon: RobertWeissfeldImage,
      name: 'Robert',
      quote:
        'After trading in my rental property about 8 months ago, everything is working as advertised.',
    },
    {
      icon: RandyMoserImage,
      name: 'Randy',
      quote:
        'It’s a way to get back your time and not have to baby sit them anymore. There’s a good rate of return, in this, you can’t really lose it. It’ll always be an asset.',
    },
    {
      icon: JohnAndReginaImage,
      name: 'John & Regina',
      quote:
        'I wanted to retire and travel, so being a landlord was getting untenable. Flock was a way to maintain our cash flow and get rid of the headaches.',
    },
  ]

  const secondItems = [
    {
      icon: WayneAndVickieImage,
      name: 'Wayne & Vickie',
      quote:
        'We have been landlords and businesspeople for a long time. We wanted to retire from that. We wanted to hand over our properties and still hold value.',
    },
    {
      icon: MarkSikorskiImage,
      name: 'Mark',
      quote:
        'I didn’t want to pay capital gains and I no longer have to worry about owning.',
    },
    {
      icon: JeremyHakesImage,
      name: 'Jeremy',
      quote:
        'Just knowing that I have more time, that I don’t have to deal with the resident process or the yard work- and still, our income has been consistent with Flock’s quarterly distributions.',
    },
    {
      icon: JackAndVickiImage,
      name: 'Jack & Vicki',
      quote:
        'I’m reaching an emotional end to being an active property manager. Flock made a lot of sense.',
    },
    {
      icon: MichaelClawsonImage,
      name: 'Michael',
      quote:
        'Flock’s commitment and ability to follow through on their word has been the most important in building trust.',
    },
    {
      icon: KatieAndMarkImage,
      name: 'Katie & Mark',
      quote:
        'We get the passive income. We get the check without having to do anything.',
    },
  ]

  return (
    <CarouselContainer>
      <InfiniteCarousel name="facts-1" margin="24px">
        {items.map(({ name, icon: Icon, quote }) => (
          <Box
            key={name}
            display="flex"
            alignItems="center"
            alignSelf="flex-end"
            padding="32px"
            gap="24px"
            borderRadius="16px"
            sx={{ backgroundColor: '#FFFFFF80' }}
            width={isTablet ? '156px' : '480px'}
            height={isTablet ? 'fit-content' : undefined}
          >
            {!isTablet && (
              <Box component="img" src={Icon} width="160px" height="160px" />
            )}
            <Box display="flex" flexDirection="column" gap="16px">
              <Box>
                <Box position="absolute" sx={{ mt: '-16px', ml: '-8px' }}>
                  <QuotationLargeIcon width="47px" height="32px" />
                </Box>
                <Typography variant="p2" color="moneyGreen.main">
                  {quote}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                gap="8px"
                alignItems="flex-end"
              >
                {isTablet && (
                  <Box component="img" src={Icon} width="48px" height="48px" />
                )}
                <Typography
                  variant="cta2"
                  color="moneyGreen.main"
                  whiteSpace={!isTablet ? 'nowrap' : undefined}
                >
                  {name}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </InfiniteCarousel>
      <Box paddingTop="24px" sx={{ backgroundColor: 'trustBlue.main' }} />
      {/* We reverse the container and the contents of the spinner to make it go in reverse properly without lagging the animation. */}
      <Box sx={{ transform: 'scaleX(-1)' }}>
        <InfiniteCarousel reverse name="facts-2" margin="24px">
          {secondItems.map(({ name, icon: Icon, quote }) => (
            <Box
              key={name}
              display="flex"
              alignItems="center"
              padding="24px"
              gap="24px"
              borderRadius="16px"
              sx={{ backgroundColor: '#FFFFFF80', transform: 'scaleX(-1)' }}
              width={isTablet ? '154px' : '480px'}
              height={isTablet ? 'fit-content' : undefined}
            >
              {!isTablet && (
                <Box component="img" src={Icon} width="160px" height="160px" />
              )}
              <Box display="flex" flexDirection="column" gap="16px">
                <Box>
                  <Box position="absolute" sx={{ mt: '-16px', ml: '-8px' }}>
                    <QuotationLargeIcon width="47px" height="32px" />
                  </Box>
                  <Typography variant="p2" color="moneyGreen.main">
                    {quote}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  gap="8px"
                  alignItems="flex-end"
                >
                  {isTablet && (
                    <Box
                      component="img"
                      src={Icon}
                      width="48px"
                      height="48px"
                    />
                  )}
                  <Typography
                    variant="cta2"
                    color="moneyGreen.main"
                    whiteSpace={!isTablet ? 'nowrap' : undefined}
                  >
                    {name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </InfiniteCarousel>
      </Box>
    </CarouselContainer>
  )
}

export default AboutCarouselSection
