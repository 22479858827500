import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const QuotationLargeIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 76}
      height={height || 52}
      viewBox="0 0 76 52"
      fill="none"
    >
      <path
        d="M18.4356 52C15.3003 52 12.4785 51.4565 9.9703 50.3693C7.58746 49.1614 5.70627 47.5308 4.32673 45.4774C3.07261 43.6655 2.0066 41.3705 1.12871 38.5923C0.376237 35.6934 0 32.6132 0 29.3519C0 23.3124 1.56766 17.6957 4.70297 12.5017C7.9637 7.187 12.9802 3.01975 19.7525 0L21.4455 3.26133C18.0594 4.71081 15.0495 6.88502 12.4158 9.78398C9.78218 12.5621 8.08911 15.5215 7.33663 18.662C6.45875 21.6818 6.33333 24.5807 6.9604 27.3589C9.9703 24.0976 14.1089 22.4669 19.3762 22.4669C24.0165 22.4669 27.7789 23.7352 30.6634 26.2718C33.5479 28.8084 34.9901 32.4321 34.9901 37.1429C34.9901 41.6121 33.4224 45.2358 30.2871 48.0139C27.2772 50.6713 23.3267 52 18.4356 52ZM59.4455 52C56.3102 52 53.4885 51.4565 50.9802 50.3693C48.5974 49.1614 46.7162 47.5308 45.3366 45.4774C44.0825 43.6655 43.0165 41.3705 42.1386 38.5923C41.3861 35.6934 41.0099 32.6132 41.0099 29.3519C41.0099 23.3124 42.5776 17.6957 45.7129 12.5017C48.9736 7.187 53.9901 3.01975 60.7624 0L62.4554 3.26133C59.0693 4.71081 56.0594 6.88502 53.4257 9.78398C50.7921 12.5621 49.099 15.5215 48.3465 18.662C47.4687 21.6818 47.3432 24.5807 47.9703 27.3589C50.9802 24.0976 55.1188 22.4669 60.3861 22.4669C65.0264 22.4669 68.7888 23.7352 71.6733 26.2718C74.5578 28.8084 76 32.4321 76 37.1429C76 41.6121 74.4324 45.2358 71.297 48.0139C68.2871 50.6713 64.3366 52 59.4455 52Z"
        fill={fillStyle || '#03341D'}
        fillOpacity="0.2"
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default QuotationLargeIcon
