import React from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FLOCK_CAREERS_URL } from '@flock/flock-component-library'
import SectionLayout from '../SharedComponents/SectionLayout'
import AboutStoryImage from '../../images/about-leadership-image.webp'

const AboutPartnerSection = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <SectionLayout
      name="about-story"
      backgroundColor="trustBlue.main"
      verticalPadding="64px"
    >
      {isTablet && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <Box
            component="img"
            src={AboutStoryImage}
            width={isMobile ? '304px' : '448px'}
            height={isMobile ? '304px' : '448px'}
          />
        </Grid>
      )}
      <Grid item xs={isTablet ? 12 : 6}>
        <Box
          display="flex"
          flexDirection="column"
          gap="32px"
          height={isTablet ? undefined : '448px'}
          justifyContent="center"
        >
          <Typography variant="h2" color="moneyGreen.main">
            Want to help change the future of real estate investing? We’re
            hiring.
          </Typography>
          <Box width="244px">
            <Button
              size="small"
              variant="secondary"
              onClick={() => window.open(FLOCK_CAREERS_URL, '_blank')}
            >
              View Openings
            </Button>
          </Box>
        </Box>
      </Grid>
      {!isTablet && (
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Box
            component="img"
            src={AboutStoryImage}
            width="448px"
            height="448px"
          />
        </Grid>
      )}
    </SectionLayout>
  )
}

export default AboutPartnerSection
